






























import { defineComponent } from "@vue/composition-api";

import ProjectActionsPanel from "@/app/components/Project/ProjectDescription/ProjectActionsPanel.vue";
import ProjectDescription from "@/app/components/Project/ProjectDescription/ProjectDescription.vue";
import ProjectPageWrapper from "@/app/components/Project/ProjectPageWrapper.vue";
import ProjectWrapper from "@/app/components/Project/ProjectWrapper.vue";
import UserListItem from "@/app/components/User/UserListItem.vue";
import { useProjectActions } from "@/shared/hooks/project/useProjectActions";

export default defineComponent({
  name: "project-team",
  components: {
    ProjectPageWrapper,
    ProjectWrapper,
    ProjectActionsPanel,
    ProjectDescription,
    UserListItem,
  },
  setup() {
    const {
      handlerArchive,
      handlerDelete,
      handleUnzip,
      innerProject,
      editProjectStatus,
      loading,
      statusIsUpdating,
      handleEdit,
    } = useProjectActions();

    return {
      innerProject,
      statusIsUpdating,
      editProjectStatus,
      handleEdit,
      handlerArchive,
      handlerDelete,
      handleUnzip,
      loading,
    };
  },
});
